import React, { useEffect, useState } from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';

const HospitalBarGraph = ({ data, year }) => {
 

  const [categories, setCategories] = useState([])
  const [measures, setMeasures] = useState([])

  useEffect(()=>{
    for (let item in data){
  
      for (let measure in data[item]){
        setMeasures(prevMeasures=>[...prevMeasures,measure])
        setCategories(prevMeasures=>[...prevMeasures,{ key: measure, title: measure.split('_').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ') }])
      } 
    }
  },[])
  

  const hospitals = ['Pawhuska', 'Carnegie', 'Seiling', 'Magnum', 'Prague'];

  // Ensure data exists and year is present in the data
  const yearData = data && 21  && data.measures_data["total_acute_admissions"][year] ? data.measures_data : null;

  console.log(`year Data: ${yearData}`);
  
  // const formattedData = hospitals.map(hospital => ({
  //   hospital,

  //   {for (let measure in measures){
  //     measure: yearData?.acute?.[year]?.[hospital] || 0,
  //   }}
  //   // acute: yearData?.acute?.[year]?.[hospital] || 0,
  //   // observation: yearData?.observation?.[year]?.[hospital] || 0,
  //   // swing_bed: yearData?.swing_bed?.[year]?.[hospital] || 0,
  //   // ed_encounters: yearData?.ed_encounters?.[hospital.toLowerCase()] || 0,
  // }));

  const formattedData = hospitals.map(hospital => {
    const hospitalData = { hospital };

    for (let measure of measures) {
      // console.log(yearData?.[measure]);
      console.log(`measssasdscdc: ${yearData?.[measure]?.[year]?.[hospital]}`);
      hospitalData[measure] = yearData?.[measure]?.[year]?.[hospital] || 0;
       
    }
    console.log(hospitalData);
    return hospitalData;
  });

  const colors = ['#0E76BC', '#FF8D4D', '#FFB60A', '#679D41', '#5F5F5F', '#264478'];

  return (
    <div className='hospital-bar-graph'>
      {categories.map((category, index) => (
        <div className='individual-graph' key={category.key}>
          <p>Statistics</p>
          <h3>{category.title}</h3>
          <ResponsiveContainer width="100%" height={262}>
            <BarChart
              width={534}
              height={262}
              data={formattedData}
              margin={{ top: 10, right: 10, bottom: 10, left: 20 }}
              layout="vertical"
            >
              <CartesianGrid stroke="none" />
              <XAxis
                type="number"
                tick={{ fontSize: 14 }}
                stroke="#FFFFF"
                tickLine={{ stroke: '#FFFFF' }}
              />
              <YAxis
                type="category"
                dataKey="hospital"
                tick={{ fontSize: 14 }}
                stroke="#FFFFF"
                tickLine={{ stroke: '#FFFFF' }}
              />
              <Tooltip />
              <Bar
                dataKey={category.key}
                fill={colors[index % colors.length]}
                barSize={20}
                barGap={5}
              />
            </BarChart>
          </ResponsiveContainer>
        </div>
      ))}
    </div>
  );
};

export default HospitalBarGraph;








