import React, { useEffect, useState } from 'react';
import api, { API_URL } from '../../api';
import PositionCard from '../../components/position/PositionCard';
import { PlusSignIcon } from 'hugeicons-react';
import "../../assets/css/position/position.css";
import { PrimaryButton } from '../../components/forms/buttons';
import DashboardContainer from '../../components/dashboard/DashboardContainer';
import FormattedDate from '../../services/formatDate';
import AddPositionPopup from '../../components/positionPopup/AddPositionPopup';
import EditPositionPopup from '../../components/positionPopup/EditPositionPopup';
import toast from 'react-hot-toast';
import "../../assets/css/position/position.css";


const PositionPageContent = () => {
    const [positions, setPositions] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [isPopupVisible, setIsPopupVisible] = useState(false);
    const [editPosition, setEditPosition] = useState(null);

    const fetchPositions = async () => {
        try {
            const response = await api.get(`${API_URL}/position/`);
            if (response.status === 200) {
                setPositions(response.data);
                setIsLoading(false);
            }
        } catch (error) {
            console.error('Failed to fetch positions:', error);
            setIsLoading(false);
        }
    };

    const handleAddPositionSubmit = async () => {
        await fetchPositions(); 
        setIsPopupVisible(false);
    };

    const handleEditPositionSubmit = async (updatedPosition) => {
        setPositions((prevPositions) =>
            prevPositions.map((pos) =>
                pos.id === updatedPosition.id
                    ? { ...pos, ...updatedPosition }
                    : pos
            )
        );
        await fetchPositions(); 
        setIsPopupVisible(false);
    };

    const handleDeletePosition = async (id) => {
        try {
            await api.delete(`${API_URL}/position/${id}`);
            setPositions((prevPositions) => prevPositions.filter((pos) => pos.id !== id));
            toast.success('Position deleted successfully');
        } catch (error) {
            console.error('Failed to delete position:', error);
            toast.error('Failed to delete position');
        }
    };

    const handleAddPosition = () => {
        setEditPosition(null);
        setIsPopupVisible(true);
    };

    const handleEditPosition = (position) => {
        setEditPosition(position);
        setIsPopupVisible(true);
    };

    const handleClosePopup = () => {
        setIsPopupVisible(false);
        setEditPosition(null);
    };

    useEffect(() => {
        fetchPositions();
    }, []);

    return isLoading ? 'Getting data..' : (
        <div className='position-page'>
            {isPopupVisible && (
                editPosition ? (
                    <EditPositionPopup
                        position={editPosition}
                        onClose={handleClosePopup}
                        onSubmit={handleEditPositionSubmit}
                        fetchPositions={fetchPositions}
                    />
                ) : (
                    <AddPositionPopup
                        onClose={handleClosePopup}
                        onSubmit={handleAddPositionSubmit}
                    />
                )
            )}
{
    localStorage.getItem("position")==="Hospital Admin" ? '' : <PrimaryButton
    isLoading={false}
    onClick={handleAddPosition}
    processingText={'Submitting'}
    iconClass={<PlusSignIcon />}
    buttonText={'Add a position'}
/>
}
            
            {positions.length > 0 ? (
                <div className="position">
                    {positions.map((pos) => (
                        <PositionCard
                            key={pos.id}
                            id={pos.id}
                            name={pos.name}
                            description={pos.description}
                            dateCreated={<FormattedDate dateString={pos.date_created} />}
                            onEditClick={() => handleEditPosition(pos)}
                            onDelete={handleDeletePosition}
                        />
                    ))}
                </div>
            ) : (
                'No data available'
            )}
        </div>
    );
};

const PositionPage = () => {
    return (
        <DashboardContainer content={<PositionPageContent />} pageTitle={'Positions'} />
    );
};

export default PositionPage;
