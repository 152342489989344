import {
    Timer02Icon,
    BedIcon,
    SearchingIcon,
    WorkoutRunIcon,
  } from "hugeicons-react";

export const singleHospitalsList = [
    {
        id: "acute",
        name: 'Acute',
        value: 55,
        icon: <Timer02Icon size={24} color={"#07AEEF"} variant={"stroke"} />
        
    },
    {
        id: "swing-bed",
        name: 'Swing Beds',
        value: 52,
        icon: <BedIcon size={24} color={"#FFB60A"} variant={"stroke"} />
        
    },
    {
        id: "observation",
        name: 'Observations',
        value: 11,
        icon: <SearchingIcon size={24} color={"#FC7D75"} variant={"stroke"} />
        
    },
    {
        id: "emergency",
        name: 'Emergency Rooms',
        value: 844,
        icon: <WorkoutRunIcon size={24} color={"#0E76BC"} variant={"stroke"} />
        
    }
]