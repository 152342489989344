import React, { useState, useEffect } from "react";
import { DataListInput, DateInput, TextInput } from "../forms/Input";
import { Flag01Icon } from "hugeicons-react";
import { PrimaryButton, SecondaryButton } from "../forms/buttons";
import axios from 'axios';
import Cookies from 'js-cookie';
import toast from 'react-hot-toast';
import dayjs from 'dayjs'; // Import dayjs for date handling
import { API_URL } from '../../api';
import MessageDescription from "../PopoupMessage/messageDescription";

const EditTarget = ({ targetData, onClose, onSubmit }) => {
  const [formData, setFormData] = useState(targetData);
  const [country, setCountry] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [measures, setMeasures] = useState([]);

  useEffect(() => {
    const fetchMeasures = async () => {
        const token = Cookies.get('accessToken');
        setIsLoading(true);
        try {
            const response = await axios.get(`${API_URL}/measures/`, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
            });
            const data = response.data;
            const measuresArray = []
            data.forEach( item =>{
            
                measuresArray.push(item['name'])
            })
            
            setMeasures(measuresArray)
            console.log(response.data)
            
        } catch (error) {
            console.error('Error fetching measures:', error);
            toast.error('Failed to load measures');
        }
    };

    fetchMeasures();
}, []);


  useEffect(() => {
    setFormData(targetData);
  }, [targetData]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevFormData => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleDateChange = (date, name) => {
    const formattedDate = dayjs(date).isValid() ? dayjs(date) : null;
    setFormData(prevFormData => ({
      ...prevFormData,
      [name]: formattedDate,
    }));
  };


const handleSubmit = async (event) => {
    event.preventDefault();  
        setIsSubmitting(true);
    const token = Cookies.get('accessToken');
const { target_id, ...dataToSend } = formData;  
  
    try {
      
const response = await axios.put(`${API_URL}/targets/update_target/${target_id}/`, dataToSend, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });
      toast.success('Target updated successfully!');
      onSubmit(response.data); 
      window.location.href = '/targets/';
    } catch (error) {
      console.error('Error updating target:', error);
      toast.error('Failed to update target');
    } finally {
      setIsSubmitting(false);
      onClose(); 
    }
  };
  

  return (
    <div className="popup-overlay">
      <div className="popup-content add-target-popup">
    
        <h2>Edit Target</h2>
        <form onSubmit={handleSubmit}>
        <MessageDescription  />
      
          <DataListInput
            iconClass={<Flag01Icon />}
            id={"measures"}
            name={"measureName"}
            placeholder={"Measure Name"}
            value={formData.measure}
            onChange={handleChange}
            setValue={setCountry}
            options={measures}
          />
          <TextInput
            type={"text"}
            id={"targetValue"}
            name={"targetValue"}
            placeholder={"Target value"}
            value={formData.value}
            setValue={(value) => handleChange({ target: { name: 'value', value } })}
          />
          <div className="dateInput">
<div className='label-dates'>
<label>Start Date</label>
<DateInput
              date={formData.starting_date ? dayjs(formData.starting_date) : null}
              setDate={(date) => handleDateChange(date, 'starting_date')}
              placeholder={"Start Date"}
              label={"Start Date"}
              choices={["day", "month", "year"]}
            />
</div>
          
<div className='label-dates'>
<label>End Date</label>
<DateInput
              date={formData.ending_date ? dayjs(formData.ending_date) : null}
              setDate={(date) => handleDateChange(date, 'ending_date')}
              placeholder={"End Date"}
              label={"End Date"}
              choices={["day", "month", "year"]}
            />
</div>
            
          </div>
          <div className="buttons">
            <SecondaryButton
              onClick={onClose}
              buttonText={"Cancel"}
            />
            <PrimaryButton
              isLoading={isSubmitting}
              processingText={"Submitting"}
              buttonText={"Save"}
              type="submit"
            />
          </div>
        </form>
      </div>
    </div>
  );
};

export default EditTarget;