import React, { useEffect, useState } from "react";
import api, { API_URL } from "../../api";
import SingleHospitalCard from "../../components/singleHospital/singleHospitalCard";
import { singleHospitalsList } from "../../data/singleHospitalsList";
import SinglePageBarChart from "../../components/singleHospital/barChart";
import SinglePagePieChart from "../../components/singleHospital/PieChart";
import SinglePageTable from "../../components/singleHospital/table";
import { PencilEdit02Icon } from "hugeicons-react";
import { SecondaryButton } from "../../components/forms/buttons";
import { useNavigate } from "react-router-dom";
import EditHospitalPopup from "../../components/singleHospital/editHospitalPopup";
import "../../assets/css/dashboard/dashboard.css";
import { FileExportIcon, ArrowDown01Icon } from "hugeicons-react";
import "../../assets/css/singleHospitalPage/singleHospital.css";
import DashboardContainer from "../../components/dashboard/DashboardContainer";
import { yearPickerClasses } from "@mui/lab";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { useParams } from "react-router-dom";
import * as XLSX from 'xlsx';

const monthToNumber = {
  January: 1, February: 2, March: 3, April: 4, May: 5, June: 6,
  July: 7, August: 8, September: 9, October: 10, November: 11, December: 12
};

function SingleHospitalPageContent({hospitalId}) {
 
  const [hospitalDetails, setHospitalDetails] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [showEditPopup, setShowEditPopup] = useState(false);
  const [hospitalName, setHospitalName] = useState('');
  const [PieChartData, setPieChartData] = useState('');
  const [measureTableData, setMeasureTableData] = useState('');
  const [singleBarChartData, setSingleBarChartData] = useState('');
  const [currentDateYear, setCurrentDateYear] = useState(new Date().getFullYear());
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
  const [selectedMonth, setSelectedMonth] = useState("January");
  const [filterDurationYear, setFilterDurationYear] = useState(
    new Date().getFullYear()
  );

  const [prevYear, setPrevYear] = useState(new Date().getFullYear() - 1);
  const [prevYearMinusTwo, setPrevYearMinusTwo] = useState(
    new Date().getFullYear() - 2
  );
  const [prevYearMinusThree, setPrevYearMinusThree] = useState(
    new Date().getFullYear() - 3
  );
  const navigate = useNavigate();
  const { id } = useParams();

  const options = ["This Year", "Last Year"];

  const handleDurationYearChange = (value) => {
    setFilterDurationYear(value);
    setSelectedYear(value)
  };

  useEffect(() => {
    const fetchHospitalDetails = async () => {
        try {
        
            const response = await api.get(`${API_URL}/hospitals/hospital/${hospitalId}/?year=${selectedYear}&month=${monthToNumber[selectedMonth] || 0}`)
        
            const data2 = response.data
            console.log("All data");
            
            console.log(data2);
            
            // const data1 = response.data["measure_table_data"][selectedYear]
            // console.log(data1);
            
            setMeasureTableData(response.data["measure_table_data"][selectedYear])

            
            setSingleBarChartData(response.data["chart_data"][selectedYear])
            
            const data = response.data["totals"]

            singleHospitalsList[0]["name"]= 'Acute'
            singleHospitalsList[0]["value"]= data['total_acute']
            singleHospitalsList[1]["name"]= 'Swing Bed'
            singleHospitalsList[1]["value"]= data['total_swing_bed']
            singleHospitalsList[2]["name"]= 'Observation'
            singleHospitalsList[2]["value"]= data['total_observation']
            singleHospitalsList[3]["name"]= 'Emergency Room'
            singleHospitalsList[3]["value"]= data['total_emergency_room']

            const pieData = response.data["patient_by_status"][selectedYear]
            
            setPieChartData(pieData)
              
            setHospitalDetails(singleHospitalsList)
            
            
        } catch (error) {
            if (error.response) {
                console.log(error.response.data.error)
            }
            console.log(error)
        }
    }
    
    fetchHospitalDetails()
    

    setTimeout(() => {
      // setHospitalDetails(singleHospitalsList);
      setIsLoading(false);
    }, 2000);
  }, [hospitalId,selectedYear,selectedMonth]);

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  }

  const handleShowPopup = () => {
    setShowEditPopup(!showEditPopup);
  };

   // Function to handle exporting table data to Excel
   const handleExport = () => {
    if (!measureTableData) return;
  
    // Create a new worksheet
    const ws = XLSX.utils.json_to_sheet(
      Object.keys(measureTableData).map((key) => {
        const row = {
          "Measures Name": key
            .split('_')
            .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
            .join(' '),
          "Goal": measureTableData[key].BM !== undefined && measureTableData[key].BM !== null ? measureTableData[key].BM : '-',
          ...Array.from({ length: 12 }, (_, i) => ({
            [`Month ${i + 1}`]: measureTableData[key]["months"][i + 1] !== undefined && measureTableData[key]["months"][i + 1] !== null 
              ? measureTableData[key]["months"][i + 1] 
              : '-',
          })).reduce((acc, monthObj) => ({ ...acc, ...monthObj }), {}),
        };
        return row;
      })
    );
  
    // Create a new workbook and append the worksheet
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Measure Data");
  
    // Export the workbook to Excel file
    XLSX.writeFile(wb, `hospital_measure_data_${selectedYear}.xlsx`);
  };
  const handleSelectedMonth = (value) => {
    setSelectedMonth(value);
  };

  

  return isLoading ? (
    "Getting data.."
  ) : (
    <div className="single-hospitals-page">
      <div className="sidebar"></div>
      <div className="main-container">
        <div className="navbar"></div>
        <div className="hospitals-container">
          <div className="top-actions">
          <div className="export-icon" onClick={handleExport}>
          <FileExportIcon size={24} />
          <p>Export measures to Excel</p>
        </div>

            <div className="hospital-year">
            <Select
          onChange={(e) => {
            handleDurationYearChange(e.target.value);
          }}
          name="duration"
          id="duration"
          value={selectedYear}
        >
          <MenuItem value={currentDateYear}>{currentDateYear}</MenuItem>
          <MenuItem value={prevYear}>{prevYear}</MenuItem>
          {/* <MenuItem value={prevYearMinusTwo}>{prevYearMinusTwo}</MenuItem> */}
          {/* <MenuItem value={prevYearMinusThree}>{prevYearMinusThree}</MenuItem> */}
        </Select>
        <Select
          onChange={(e) => {
            handleSelectedMonth(e.target.value);
          }}
          name="selectedMonth"
          id="selectedMonth"
          value={selectedMonth}
        >
          <MenuItem value="January">January</MenuItem>
          <MenuItem value="February">February</MenuItem>
          <MenuItem value="March">March</MenuItem>
          <MenuItem value="April">April</MenuItem>
          <MenuItem value="May">May</MenuItem>
          <MenuItem value="June">June</MenuItem>
          <MenuItem value="July">July</MenuItem>
          <MenuItem value="August">August</MenuItem>
          <MenuItem value="September">September</MenuItem>
          <MenuItem value="October">October</MenuItem>
          <MenuItem value="November">November</MenuItem>
          <MenuItem value="December">December</MenuItem>
        </Select>
            </div>
            {/* <SecondaryButton
              buttonText="Edit Details"
              isLoading={isSubmitting}
              onClick={handleShowPopup}
              processingText={"Saving"}
              iconClass={<PencilEdit02Icon />}
            /> */}
          </div>
          {hospitalDetails && hospitalDetails.length > 0 ? (
            <div className="total-hospital-cards">
              {hospitalDetails.map((hospital, index) => (
                <SingleHospitalCard
                  className="total-card"
                  key={index}
                  id={hospital.id}
                  name={hospital.name}
                  value={hospital.value}
                  icon={hospital.icon}
                />
              ))}
            </div>
          ) : (
            "No data available"
          )}

          <div className="charts">
            <div className="bar-chart-container">
              <SinglePageBarChart singleBarChartData={singleBarChartData} hospitalId={hospitalId}/>
            </div>
            <div className="pie-chart-container">
              <SinglePagePieChart PieChartData={PieChartData}/>
            </div>
          </div>

          <div className="table-container">
            <h3>Performance Measures</h3>
            <SinglePageTable measuresTableData={measureTableData}/>
          </div>

          {showEditPopup && (
            <div className="overlay">
              <EditHospitalPopup />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

const SingleHospitalPage = () => {
  const { hospitalName } = useParams();
  const { hospitalId } = useParams();
  return (
    <DashboardContainer
      content={<SingleHospitalPageContent hospitalId={hospitalId}/>}
      pageTitle={hospitalName}
    />
  );
};

export default SingleHospitalPage;
