import React, { useState, useEffect } from 'react';
import { PrimaryButton, SecondaryButton } from '../../../components/forms/buttons';
import { TextInput, DataListInput } from '../../../components/forms/Input';
import api, { API_URL } from '../../../api';
import toast from 'react-hot-toast';
import axios from 'axios';
import Cookies from 'js-cookie';

const EditMeasureForm = ({ measureId, measureData, onClose }) => {
    const [formData, setFormData] = useState({
        name: '',
        category_name: '', 
    });
    const [categories, setCategories] = useState([]);

    useEffect(() => {
        // Set measure data to the form state when component mounts or measureData changes
        if (measureData) {
            setFormData({
                name: measureData.name || '',
                category_name: measureData.category_name || '',
            });
        }
    }, [measureData]);

    // Fetch categories list when the component is mounted
    useEffect(() => {
        const fetchCategories = async () => {
            try {
                const token = Cookies.get('accessToken');
                const response = await axios.get(`${API_URL}/categories/list/`, {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                    },
                });
                const categoryNames = response.data.map(category => category.name);
                setCategories(categoryNames);
            } catch (error) {
                console.error('Error fetching categories:', error);
                toast.error('Failed to fetch categories');
            }
        };

        fetchCategories();
    }, []);

    const handleInputChange = (name, value) => {
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const payload = {
            category: formData.category_name, 
            name: formData.name,
        };

        try {
            const response = await api.put(`${API_URL}/measures/update-measure/${measureId}/`, payload);
            if (response.status === 200) {
                toast.success('Measure updated successfully');
                onClose(); 
                window.location.reload(); 
            }
        } catch (error) {
            toast.error(error.response?.data?.error || 'Error updating measure');
        }
    };

    return (
        <form onSubmit={handleSubmit}>
            <div className="form-group">
                <TextInput
                    label="Measure Name"
                    name="name"
                    value={formData.name}
                    setValue={(value) => handleInputChange('name', value)}
                />
            </div>

            <div className="form-group">
                <DataListInput
                    label="Category"
                    name="category_name"
                    value={formData.category_name}
                    setValue={(value) => handleInputChange('category_name', value)}
                    options={categories}
                />
            </div>

            <div className="buttons">
                <SecondaryButton buttonText="Cancel" onClick={onClose} />
                <PrimaryButton type="submit" buttonText="Update Measure" />
            </div>
        </form>
    );
};

export default EditMeasureForm;
