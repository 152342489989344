import React from 'react'
import { tableData } from '../../data/tableData'

function SinglePageTable({measuresTableData}) {

    console.log("table data");
    console.log(measuresTableData);
    
    

  return (
    <>
    <div className="table">
                
        <table>
            <thead>
                <tr>
                    <th>Measures Name</th>
                    <th>Goal</th>
                    <th>01</th>
                    <th>02</th>
                    <th>03</th>
                    <th>04</th>
                    <th>05</th>
                    <th>06</th>
                    <th>07</th>
                    <th>08</th>
                    <th>09</th>
                    <th>10</th>
                    <th>11</th>
                    <th>12</th>
                </tr>
            </thead>
            
            <tbody>
    {
        measuresTableData && Object.keys(measuresTableData).length > 0 && (
            <>
                {["CENSUS - VOLUME & UTILIZATION", "CLINICAL QUALITY", "HUMAN RESOURCES"].map((category) => {
                    const measuresInCategory = Object.keys(measuresTableData).filter(measure => 
                        measuresTableData[measure].category === category // Assuming your data has a 'category' field
                    );

                    return (
                        <React.Fragment key={category}>
                            <tr>
                                <td colSpan={13} style={{ fontWeight: 'bold' }}>{category}</td>
                            </tr>
                            {measuresInCategory.map((measureData, index) => (
                                <tr key={index}>
                                    <td>
                                        {measureData.split('_').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ')}
                                    </td>
                                    <td>{measuresTableData[measureData].measure_unit === "percentage"?measuresTableData[measureData].BM: measuresTableData[measureData].BM? measuresTableData[measureData].BM: '-'}
                                    {measuresTableData[measureData].measure_unit === "percentage" ? "%": ""}
                                    </td>
                                    {[...Array(12)].map((_, monthIndex) => (
                                        <td key={monthIndex + 1}>
                                            {measuresTableData[measureData]["months"][monthIndex + 1]}
                                            {measuresTableData[measureData].measure_unit === "percentage" &&measuresTableData[measureData]["months"][monthIndex + 1] ? "%": ""}
                                        </td>
                                    ))}
                                </tr>
                            ))}
                        </React.Fragment>
                    );
                })}
            </>
        )
    }
</tbody>

        </table>
    </div>
    </>
  )
}

export default SinglePageTable