import React from 'react';
import { PieChart } from '@mui/x-charts';

function SinglePagePieChart({ PieChartData }) {
  console.log("PieChartData:", PieChartData); 

  const pieChartData = [];
  
  for (const data in PieChartData) {
    console.log(`Processing: ${data} - ${PieChartData[data]}`);
    if (PieChartData[data] > 0) {
      pieChartData.push({ label: data, value: PieChartData[data], color: '#07AEEF' });
    }
  }

  console.log("Transformed pieChartData:", pieChartData); 
  const totalValue = pieChartData.reduce((sum, item) => sum + item.value, 0); // C

  const data = pieChartData.length > 0 ? pieChartData : [{ label: 'No Data', value: 1, color: '#07AEEF' }];

  return (
    <>
    <div className="statistics">
        <div className="title">
            <p>Statistics</p>
            <h3>
            Falls by Injury Type</h3>
        </div>
        <div className="divider"></div>
        <div className="pie-chart" >
      <PieChart
        series={[
          {
            data,
            innerRadius: 50,
            outerRadius: 80,
            cornerRadius: 0,
            startAngle: 0,
          },
        ]}
        height={300}
        title="Patient By Status"
        legend={{ hidden: true }}
        colors={data.map(item => item.color)} 
      />

{/* <CustomCenterLabel label="Totals" number={pieChartData.total} /> */}
<CustomLegend data={data} />

        </div>
    </div>
    </>
  );
}

export default SinglePagePieChart;


const CustomCenterLabel = ({ label, number }) => {
  return (
    <div className='center-label'>
      <div className='label'>{label}</div>
      <div className='number'>{number}</div>
    </div>
  );
};

function capitalizeFirstLetter(word) {
  if (!word) return ''; 
  return word.split('_').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
}

const CustomLegend = ({ data }) => {

  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: '12px',  }}>
      {data.map((item, index) => (
        <div key={index} style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
          <span style={{
            display: 'inline-block',
            width: '12px',
            height: '12px',
            borderRadius: '50%',
            backgroundColor: item.color
          }}></span>
          <span style={{ color: '#999999'}}>{capitalizeFirstLetter(item.label)}</span>
          <span style={{ fontWeight: '600'}}>{item.label == "No Data"? 0: item.value}%</span>
        </div>
      ))}
    </div>
  );
};


