import { AlertDiamondIcon } from "hugeicons-react";
import React, { useState } from "react";
import axios from "axios";
import { API_URL } from "../../api";
import { PrimaryButton, SecondaryButton } from "../forms/buttons";
import Cookies from "js-cookie";
import toast from 'react-hot-toast';

const DeletePositionPopup = ({ positionId, onClose, onDelete }) => {
    const [isSubmitting, setIsSubmitting] = useState(false);
    const handleSubmit = async (e) => {
      e.preventDefault();
      if (!positionId) {
          toast.error("Position ID is not defined.");
          return;
      }
  
      setIsSubmitting(true);
      const token = Cookies.get("accessToken");
      try {
          const response = await axios.delete(`${API_URL}/position/delete_position/${positionId}/`, {
              headers: {
                  Authorization: `Bearer ${token}`,
              },
          });
  
          console.log('Response:', response);
  
          if (response.status === 204 || response.status === 200) {
              toast.success("Position deleted successfully.");
              onDelete();
              onClose();
          } else {
              toast.error(`Failed to delete the position. Status code: ${response.status}`);
          }
      } catch (error) {
          console.error("Error deleting position:", error); 

         
      } finally {
          setIsSubmitting(false);
      }
  };
  

    return (
        <div className="popup-overlay">
            <form className="delete-position-popup" onSubmit={handleSubmit}>
                <div className="icon-text">
                    <AlertDiamondIcon className="icon" />
                    <div className="text">
                        <h4>Delete</h4>
                        <p>Do you really want to delete the position with ID {positionId}?</p>
                    </div>
                </div>
                <div className="buttons">
                    <SecondaryButton onClick={onClose} buttonText={"Cancel"} />
                    <PrimaryButton isLoading={isSubmitting} processingText={"Deleting"} buttonText={"Delete"} />
                </div>
            </form>
        </div>
    );
};

export default DeletePositionPopup;
