import React, { useState, useEffect, useRef } from 'react';
import { PrimaryButton, SecondaryButton } from '../../../components/forms/buttons';
import { CleanIcon, Edit02Icon, Delete01Icon, PlusSignIcon, Search01Icon } from 'hugeicons-react';
import { TextInput } from '../../../components/forms/Input';
import api, { API_URL } from '../../../api';
import toast from 'react-hot-toast';
import FormattedDate from '../../../services/formatDate';
import AddNewMeasureData from '../../../components/forms/measures/MeasureData/AddNewMeasureData';
import EditMeasureData from '../../../components/forms/measures/MeasureData/EditMeasureData';
import DeleteMeasureData from '../../../components/forms/measures/MeasureData/DeleteMeasureData';
import '../../../assets/css/pages/measures/measures.css';
import DashboardContainer from '../../../components/dashboard/DashboardContainer';

const MeasuresTable = ({ measures = [], onEditClick, onDeleteClick }) => {
    const [selectedMeasures, setSelectedMeasures] = useState([]);
   

    const handleSelectMeasure = (measureId) => {
        const index = selectedMeasures.indexOf(measureId);
        if (index === -1) {
            setSelectedMeasures([...selectedMeasures, measureId]);
        } else {
            setSelectedMeasures(selectedMeasures.filter((id) => id !== measureId));
        }
    };

    return measures.length < 1 ? '..Nothing found' : (
        <div className="table-container">
            <table>
                <thead>
                    <tr>
                        <th></th>
                        <th>Measure ID</th>
                        <th>Measure Name</th>
                        <th>Value</th>
                        <th>Date Created</th>
                        <th>Reporting Period</th>
                        <th>Hospital</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {measures.map((measure) => (
                        <tr key={measure.measure_data_id}>
                            <td><input onChange={() => handleSelectMeasure(measure.measure_data_id)} type="checkbox" /></td>
                            <td>{measure.measure_data_id}</td>
                            <td>{measure.name.split('_').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ')}</td>
                            <td>{measure.value}</td>
                            <td><FormattedDate dateString={measure.date_created} /></td>
                            <td><FormattedDate dateString={measure.reporting_date} /></td>
                            <td>{measure.hospital || 'N/A'}</td>
                            <td>
                                <Delete01Icon onClick={() => onDeleteClick(measure.measure_data_id)} />
                                <Edit02Icon onClick={() => onEditClick(measure.measure_data_id)} />
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>

            <div className="selected-measures">
                {selectedMeasures.map((measure) => (
                    <div key={measure} className="selected-measure">
                        <h4>{measure}</h4>
                    </div>
                ))}
            </div>
        </div>
    );
};

const MeasureDataContent = () => {
    const [measuresData, setMeasuresData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [errorFetching, setErrorFetching] = useState(false);
    const [searchQuery, setSearchQuery] = useState('');
    const [filteredData, setFilteredData] = useState([]);
    const [isSearching, setIsSearching] = useState(false);
    const [showNewMeasureForm, setShowNewMeasureForm] = useState(false);
    const [showEditMeasureForm, setShowEditMeasureForm] = useState(false);
    const [selectedMeasureId, setSelectedMeasureId] = useState(null);
    const [measureData, setMeasureData] = useState({});
    const [showDeleteMeasurePopup, setShowDeleteMeasurePopup] = useState(false);
    const [selectedMeasureForDeletion, setSelectedMeasureForDeletion] = useState(null);
    const [sortedData, setSortedData] = useState([]);
    const [sortCondition, setSortCondition] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 50;
    const [totalMeasures, setTotalMeasures] = useState(0); 
    const [constMeasureData, setConstMeasureData] = useState({});

    const [measureName, setMeasureName] = useState('');
  
    const [error, setError] = useState('');
    const debounceTimeout = useRef(null);
   

    const handleSearch = async (query) => {
        if (!query) {
            
            setSortedData(constMeasureData)

            return;
        }
       
        try {
            
            setError(''); 
            
            const response = await api.get(`${API_URL}/measures/search/`, {
                params: { measure_name: query } 
            });
            console.log(response.data)
            const sortedMeasures = response.data.sort((a, b) => new Date(a.date_created) - new Date(b.date_created));
    
            setMeasuresData(sortedMeasures);
            setSortedData(sortedMeasures);
           
        } catch (err) {
            setError('Failed to fetch data. Please try again.'); 
            console.error(err);
        }
    };

    const handleInputChange = (value) => {
        setMeasureName(value);

        // Clear the previous debounce timeout
        clearTimeout(debounceTimeout.current);

        // Set a new debounce timeout
        debounceTimeout.current = setTimeout(() => {
            handleSearch(value); 
        }, 300); 
    };

    useEffect(() => {
        return () => {
            clearTimeout(debounceTimeout.current); // Clean up on unmount
        };
    }, []);

    const filterMeasures = (data, searchQuery) => {
        if (!searchQuery.trim()) {
          return data;
        }
      
        return data.filter(measure => 
          measure.name && typeof measure.name === 'string' && 
          measure.name.toLowerCase().includes(searchQuery.toLowerCase())
        );
      };
    const handleSearches = () => {
        setIsSearching(true);
        const filtered = filterMeasures(measuresData, searchQuery);
        setSortedData(filtered);
        setIsSearching(false);
      };


      const fetchMeasures = async (page = 1, itemsPerPage = 100, searchQuery = '') => {
        setErrorFetching('');
        setIsLoading(true);
        try {
            const response = await api.get(`${API_URL}/measures/measures_hospital_list/`, {
                params: {
                    page: page,
                    page_size: itemsPerPage,
                    search: searchQuery,  // Pass search query to API
                }
            });
    
            if (response.status === 200) {
                const sortedMeasures = response.data["results"].sort((a, b) => new Date(a.date_created) - new Date(b.date_created));
    
                setMeasuresData(sortedMeasures);
                setSortedData(sortedMeasures);
                setConstMeasureData(sortedMeasures)
                setIsLoading(false);
            }
        } catch (error) {
            toast.error(error.response?.data.error || 'Error while fetching measures');
            setErrorFetching(true);
            setIsLoading(false);
        }
    };
    
  
    useEffect(() => {
        fetchMeasures(); 

        return () => {
            clearTimeout(debounceTimeout.current); // Clean up on unmount
        };
    }, []);

    const handleAddSuccess = () => {
        fetchMeasures(); 
    };

  
    
   
  

    const filterByRecent = (condition) => {
        let sorted = [...(isSearching ? filteredData : measuresData)];
        if (condition === 'recent') {
            sorted = sorted.sort((a, b) => new Date(b.date_created) - new Date(a.date_created));
        } else if (condition === 'oldest') {
            sorted = sorted.sort((a, b) => new Date(a.date_created) - new Date(b.date_created));
        }
        setSortedData(sorted);
        setSortCondition(condition);
    };

    const handleEditMeasure = (measure_data_id) => {
        console.log(`Editing measure with ID: ${measure_data_id}`);
        const measureData = measuresData.find(measure => measure.measure_data_id === measure_data_id);
        if (measureData) {
            setSelectedMeasureId(measure_data_id);
            setMeasureData(measureData);
            setShowEditMeasureForm(true);
        } else {
            console.error('Measure not found');
        }
    };

    const handleCloseEditForm = () => {
        setShowEditMeasureForm(false);
        setSelectedMeasureId(null);
    };

    const handleDeleteClick = (measureId) => {
        console.log(`Preparing to delete measure with ID: ${measureId}`);
        setSelectedMeasureForDeletion(measureId);
        setShowDeleteMeasurePopup(true);
    };

    const indexOfLastMeasure = currentPage * itemsPerPage;
    const indexOfFirstMeasure = indexOfLastMeasure - itemsPerPage;
    const currentMeasures = sortedData.slice(indexOfFirstMeasure, indexOfLastMeasure);
    
    
   
    const handlePage = (pageNumber) => {
        if (pageNumber > 0) {
            setCurrentPage(pageNumber);
            fetchMeasures(pageNumber, itemsPerPage, searchQuery);  // Ensure search works with pagination
        }
    };
    
    
    return isLoading ? 'getting data...' : errorFetching ? 'Error while getting measures' : (
        <div className='measures-page'>
            {showNewMeasureForm && (
                <div className="popup new-measure-form-popup">
                    <div className="popup-content">
                        <AddNewMeasureData
                            setShowNewMeasureFrom={setShowNewMeasureForm}
                            onClose={() => setShowNewMeasureForm(false)}
                            onAddSuccess={handleAddSuccess}
                        />
                    </div>
                </div>
            )}
            {showEditMeasureForm && selectedMeasureId && (
                <div className="popup edit-measure-form-popup">
                    <div className="popup-content">
                        <EditMeasureData
                            measure_data_id={selectedMeasureId}
                            measureData={measureData}
                            onClose={handleCloseEditForm}
                        />
                    </div>
                </div>
            )}
            {showDeleteMeasurePopup && selectedMeasureForDeletion && (
                <DeleteMeasureData
                    measureId={selectedMeasureForDeletion}
                    isOpen={showDeleteMeasurePopup}
                    onClose={() => setShowDeleteMeasurePopup(false)}
                    removeMeasure={(measureId) => {
                        setMeasuresData(measuresData.filter(measure => measure.measure_data_id !== measureId));
                        setShowDeleteMeasurePopup(false);
                    }}
                />
            )}
            <div className="search-filter">
                <div className="filters">
                <TextInput
    iconClass={<Search01Icon />}
    type='search'
    placeholder='Search by measure name'
    value={measureName}
    setValue={handleInputChange}
    
/>

                    {isSearching
                        ? <SecondaryButton 
    iconClass={<CleanIcon />} 
    buttonText='Clear' 
    onClick={() => { 
        setSearchQuery(''); 
        setSortedData(measuresData); 
    }} 
/>

                        : <PrimaryButton isLoading={false} onClick={handleSearches} processingText='Searching' buttonText='Search' />
                    }
                    <select name="" id="" onChange={(e) => filterByRecent(e.target.value)}>
<option value="">Sort by oldest</option>
                        <option value="recent">Sort by recent</option>
                    </select>
                </div>
                <div className="action">
                    <PrimaryButton iconClass={<PlusSignIcon />} buttonText='New measure data' onClick={() => setShowNewMeasureForm(true)} />
                </div>
            </div>

            <div className="measures">
                <MeasuresTable measures={sortedData} onEditClick={handleEditMeasure} onDeleteClick={handleDeleteClick} />
            </div>

            <div className="pagination">
        <button
            className='buttons prev-btn'
            onClick={() => handlePage(currentPage - 1)}
            disabled={currentPage === 1}
        >
            Previous
        </button>
        {Array.from({ length: Math.ceil(totalMeasures / itemsPerPage) }, (_, i) => (
            <button
                key={i + 1}
                className={`buttons ${currentPage === i + 1 ? 'active' : 'pagination-btn'}`}
                onClick={() => handlePage(i + 1)}
            >
                {i + 1}
            </button>
        ))}
        <button
            className='buttons next-btn'
            onClick={() => handlePage(currentPage + 1)}
            disabled={currentPage === Math.ceil(totalMeasures / itemsPerPage)}
        >
            Next
        </button>
    </div>

        </div>
    );
};

const MeasureDataPage = () => {
    return (
        <DashboardContainer content={<MeasureDataContent />} pageTitle={'Measured data'} />
    );
};

export default MeasureDataPage;