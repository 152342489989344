import { ArrowDown01Icon, ArrowRight01Icon, Menu01Icon, Notification02Icon, UserIcon, Logout01Icon } from 'hugeicons-react'
import React, { useEffect, useState } from 'react'
import { Link,useNavigate } from 'react-router-dom'
import { useParams } from 'react-router-dom';
import { API_URL } from '../../api';
import axios from 'axios';
import toast from 'react-hot-toast';
import Cookies from 'js-cookie';

const DashboardHeader = ({ mobileMenuOpen, setMobileMenuOpen, pageTitle }) => {
    const [profileData, setProfileData] = useState({});
    const [isProfileMenuOpen, setIsProfileMenuOpen] = useState(false);  

    const path = window.location.pathname;
    const [userData, setUserData] = useState({});
    const [fullName, setFullName] = useState('');
const [position, setposition] = useState('');  
const [profile_image, setProfileImage] = useState('');
    const { userId } = useParams();
    const [isLoading, setIsLoading] = useState(true);

    // Getting breadcrumbs from the URL
    const breadcrumbs = path.split('/').filter(Boolean);
    breadcrumbs.unshift('dashboard');
    breadcrumbs.forEach((breadcrumb, index) => {
        breadcrumbs[index] = <span className='breadcrumb' key={index}>{index > 0 ? <ArrowRight01Icon size={20} /> : ''}{breadcrumb}</span>;
    });

    useEffect(() => {
        const fetchUserData = async () => {
            try {
                const token = Cookies.get('accessToken');
                const response = await axios.get(`${API_URL}/user/profile/`, {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                    },
                });
                const user = response.data;

                setFullName(`${user.first_name} ${user.last_name}` || '');
setposition(user.position); 
setProfileImage(user.profile_image);   
                setProfileData(user);
                setIsLoading(false);
            } catch (error) {
                console.error('Error fetching user data:', error);
                toast.error('Failed to load user data.');
                setIsLoading(false);
            }
        };

        fetchUserData();
    }, [userId]);

const handleLogout = (navigate) =>{
    Cookies.remove('accessToken');
navigate('/login');
}
const history = useNavigate();

    return (
        <div className='dashboard-header'>
            <div className="header-content">
                <div className="breadcrumbs">
                    <h4 className="page-title">{pageTitle}</h4>
                    <div className="breadcrumb-list">
                        {breadcrumbs}
                    </div>
                </div>

                <div className="profile-and-links">
                    <div className="notification">
                        <div className="dot"></div>
                        <Notification02Icon />
                    </div>

                    <div className="profile" onClick={() => setIsProfileMenuOpen(!isProfileMenuOpen)}>
                        {profileData && profileData.image
                            ? <img src={profileData.image} alt={profileData.name} />
                            : <img src="/favicon.ico" alt="" className="logo" />
                        }
                        <ArrowDown01Icon size={24} color={"#000000"} variant={"stroke"} />
                    </div>

                    {isProfileMenuOpen && (
                        <>
                            <div className='profile-overlay' onClick={() => setIsProfileMenuOpen(false)}></div>

                            <div className='profile-drop-down'>
<div className="profile-name">
<img src={profile_image || '/favicon.ico'} alt="" />
<div className='name-position'>
<p>{fullName}</p>
<p>{position || 'N/A'} </p>
</div>
                 
                                </div>
<div className="profile-menu">
<div className="profile-item">
                                        <UserIcon size={24} color={"#000000"} variant={"stroke"} />
                                        <Link to='/account/'>My Profile</Link>
                                    </div>
                                    <div className="profile-item" onClick={() => handleLogout(history)}>
            <Logout01Icon size={24} color={"#000000"} variant={"stroke"} />
            <span>Logout</span>
        </div>

                                </div>
                            </div>
                        </>
                    )}

<Menu01Icon size={32} className='openMenu' onClick={() => setMobileMenuOpen(true)} />
                </div>
            </div>
        </div>
    );
}

export default DashboardHeader;
