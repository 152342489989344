import React, { useState } from 'react';
import { TextInput, DateInput , TextAreaInput } from '../forms/Input';
import { PrimaryButton, SecondaryButton } from '../forms/buttons';
import axios from 'axios';
import Cookies from 'js-cookie';
import toast from 'react-hot-toast';
import { API_URL } from '../../api';
import "../../../src/assets/css/popups/add.css"

const AddPositionPopup = ({ onClose, onSubmit = () => {} }) => {
    const [positionName, setPositionName] = useState('');
    const [dateCreated, setDateCreated] = useState(null);
    const [positionDescription, setPositionDescription] = useState('');
    const [isSubmitting, setIsSubmitting] = useState(false);

    // const formatDate = (dateString) => {
    //     if (!dateString) return null;
    //     const date = new Date(dateString);
    //     return date.toISOString().split('T')[0];
    // };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (isSubmitting) return;
        setIsSubmitting(true);
    
        try {
            const newPosition = {
                name: positionName,
// date_created: formatDate(dateCreated),
                description: positionDescription,
            };
    
            const token = Cookies.get('accessToken');
            const response = await axios.post(`${API_URL}/position/new_position/`, newPosition, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
            });
    
            if (response.status === 200 || response.status === 201) {
                const createdPosition = response.data;
                toast.success("Position successfully added");
                onSubmit(createdPosition); 
                onClose(); 
            } else {
                toast.error("Failed to add position");
            }
        } catch (error) {
            console.error(error);
            if (error.response && error.response.status === 409) {
                toast.error('A position with this name already exists.');
            } else {
                toast.error('Something went wrong. Please try again');
            }
        } finally {
            setIsSubmitting(false);
        }
    };
    
    
    

    return (
        <div className='popup-overlay'>
            <form className='add-position-popup' onSubmit={handleSubmit}>
                <h1 className='title'>Add New Position</h1>
                <TextInput
                    id="positionName"
                    name="positionName"
                    placeholder="Position Name"
                    value={positionName}
                    setValue={setPositionName}
                />
                {/* <DateInput
                    date={dateCreated}
                    setDate={setDateCreated}
                    placeholder="Date Created"
                    label="Date Created"
                    choices={['day', 'month', 'year']}
                /> */}
                <TextInput
                    type='text'
                    id='positionDescription'
                    name='positionDescription'
                    placeholder="Position Description"
                    value={positionDescription}
                    setValue={setPositionDescription}
                    multiline 
                />
                <div className='buttons'>
                    <SecondaryButton
                        onClick={onClose}
                        buttonText='Cancel'
                    />
                    <PrimaryButton
                        isLoading={isSubmitting}
                        type='submit'
                        processingText='Submitting'
                        buttonText='Save'
                    />
                </div>
            </form>
        </div>
    );
};

export default AddPositionPopup;
