import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { API_URL } from '../../api';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import DashboardContainer from '../../components/dashboard/DashboardContainer';
import { SecondaryButton } from '../../components/forms/buttons';
import { CheckmarkSquare01Icon } from 'hugeicons-react';
import EditProfilePopup from '../../components/user/UserDetails/EditProfilePopup';

const UserDetailsPage = () => {
  const { userId } = useParams();
  const [userData, setUserData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [profile, setProfile] = useState({
    profilePicture: '',
    username: '',
    position: '',
    account_id: '',
    email: '',
    first_name: '',
    last_name: '',
    hospital: '',
    groups: [],
    date_created: '',
  });
  

  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    const fetchUserDetails = async () => {
      try {
        console.log("Fetching user with id (userId):", userId);
        const response = await axios.get(`${API_URL}/accounts/single_user/${userId}/`);
        const userDetails = response.data;

        if (userDetails) {
          setUserData(userDetails);
          setProfile({
profilePicture: userDetails.profilePicture || '/favicon.ico',
            username: userDetails.username,
            position: userDetails.position,
            account_id: userDetails.account_id,
            email: userDetails.email,
            first_name: userDetails.first_name,
            last_name: userDetails.last_name,
            hospital: userDetails.hospital,
            groups: userDetails.groups || [],
            date_created: userDetails.date_created,
          });
        }
      } catch (error) {
        console.error('Error fetching user details:', error);
        toast.error('Failed to load user details');
      } finally {
        setIsLoading(false);
      }
    };

    fetchUserDetails();
  }, [userId]);

  const openPopup = () => setIsPopupOpen(true);
  const closePopup = () => setIsPopupOpen(false);

  const handleSave = (updatedData) => {
    setProfile((prevProfile) => ({
      ...prevProfile,
      ...updatedData,
    }));
  };

  return isLoading ? (
    <p>Loading...</p>
  ) : (
    <div>
      <h2>User Details</h2>
      <div className="user-profile">
        <ToastContainer position={'top-center'} />
        <SecondaryButton
          isLoading={isSubmitting}
          onClick={openPopup}
          buttonText='Edit Details'
          processingText={'Saving'}
          iconClass={<CheckmarkSquare01Icon />}
        />

        <div className="profile-info-container">
          <div className="user-details">
            <div className="user-image">
              <img src={profile.profilePicture || 'image/profil/profilphoto.jpg'} alt="Profile" />
            </div>
            <p>{profile.username}</p>
            <div className="personal">
              <div className='position-details'>
                <p className='position'>Position: </p>
                <p>{profile.position}</p>
              </div>
              <div className='vertical-container'>
                <hr className="vertical-line" />
              </div>
              <div className='id-details'>
                <p className='id'>ID: </p>
                <p>{profile.account_id}</p>
              </div>
            </div>
          </div>

          <div className="view-information">
            <div className="user-info">
              <div className="individual-user">
                <div className="details-box">
                  <p className='text'>Username: </p>
                  <p>{profile.username}</p>
                  <hr />
                </div>
                <div className='details-box'>
                  <p className='text'>Email:</p>
                  <p>{profile.email}</p>
                  <hr />
                </div>
                <div className='details-box'>
                  <p className='text'>Date Added:</p>
                  <p>{profile.date_created}</p>
                  <hr />
                </div>
              </div>

              <div className="individual-user">
                <div className='details-box'>
                  <p className='text'>Firstname: </p>
                  <p>{profile.first_name}</p>
                  <hr />
                </div>
                <div className="individual-user">
            <div className='details-box'>
              <p className='text'>Groups:</p>
              <p>
                {profile.groups?.length > 0 ? (
                  profile.groups.map(group => group.name).join(', ')
                ) : (
                  'No groups assigned'
                )}
              </p>
              <hr />
            </div>
          </div>
              </div>

              <div className="individual-user">
                <div className='details-box'>
                  <p className='text'>Lastname: </p>
                  <p>{profile.last_name}</p>
                  <hr />
                </div>
                <div className='details-box'>
                  <p className='text'>Hospital:</p>
                  <p>{profile.hospital}</p>
                  <hr />
                </div>
              </div>
            </div>
          </div>
        </div>

        <EditProfilePopup
  userId={userId} 
  isOpen={isPopupOpen}
  closePopup={closePopup}
  profile={profile}
  onSave={handleSave}
/>

      </div>
      <ToastContainer />
    </div>
  );
};

const UserDetails = () => {
  return (
    <DashboardContainer content={<UserDetailsPage />} pageTitle={'Users'} />
  );
};

export default UserDetails;
