import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Cookies from 'js-cookie';
import { TextInput, DataListInput, DateInput } from '../../Input';
import { PrimaryButton, SecondaryButton } from '../../../../components/forms/buttons';
import toast from 'react-hot-toast';
import { API_URL } from '../../../../api';
import "../../../../assets/css/profile/profile.css";
import { Flag01Icon } from "hugeicons-react";
import dayjs from 'dayjs';

const EditMeasureData = ({ measure_data_id, measureData, onClose, onSubmit }) => {
    const [formData, setFormData] = useState({
        hospital: '',
        measure: '',
        value: '',
        reporting_date: ''
    });

    const [isSaving, setIsSaving] = useState(false);
    const [error, setError] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const [reportingDate, setReportingDate] = useState(null);  
    const [measure, setMeasure] = useState([]); 

    useEffect(() => {
        if (measureData && Object.keys(measureData).length > 0) {
            setFormData({
                hospital: measureData.hospital || '',
                measure: measureData.measure_name || '',
                value: measureData.value || '',
type: measureData.value || 'numerical',
                reporting_date: measureData.reporting_date ? dayjs(measureData.reporting_date).format('YYYY-MM-DD') : ''  
            });
            setReportingDate(measureData.reporting_date ? dayjs(measureData.reporting_date) : null); 
        }
    }, [measureData]);
    
    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsSaving(true);
        setError('');
        setSuccessMessage('');
    
        // Check if all required fields are filled
if (!formData.hospital || !formData.measure || !formData.value || !formData.reporting_date || !formData.type) {
            setError('All fields are required.');
            setIsSaving(false);
            return;
        }
    
        try {
            const token = Cookies.get('accessToken');
            const response = await axios.put(
                `${API_URL}/measures/update_measure_data_api/${measure_data_id}/`,
                formData,
                {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json',
                    },
                }
            );
    
            if (response.status === 200) {
                toast.success('Measure updated successfully!');
                if (onSubmit) onSubmit();
                onClose();
            } else {
                toast.error('Failed to update measure');
            }
        } catch (error) {
            console.error('API error:', error.response ? error.response.data : error.message);
            toast.error('An error occurred while updating measure data.');
        } finally {
            setIsSaving(false);
        }
    };
    
    
    

    const handleCancel = (e) => {
        e.preventDefault();
        onClose();
    };

    return (
        <div className="overlay">
            <div className="popup-user">
                <form onSubmit={handleSubmit}>
                    <h2>Edit Measure</h2>
                    {error && <div className="error-message">{error}</div>}
                    {successMessage && <div className="success-message">{successMessage}</div>}

                    <TextInput
                        id="hospital"
                        name="hospital"
                        placeholder="Hospital"
                        value={formData.hospital}
                        setValue={(value) => setFormData((prevFormData) => ({ ...prevFormData, hospital: value }))}
                    />

                    <DataListInput
                        iconClass={<Flag01Icon />}
                        id="measure"
                        name="measureName"
                        placeholder="Measure name"
                        value={formData.measure} 
                        options={measure || []}
                        setValue={(value) => setFormData((prevFormData) => ({ ...prevFormData, measure: value }))}
                    />

                    <TextInput
                        id="value"
                        name="value"
                        placeholder="Value"
                        type="number"
                        value={formData.value}
                        setValue={(value) => setFormData((prevFormData) => ({ ...prevFormData, value: value }))}
                    />

<DateInput
    id="reporting_date"
    name="reporting_date"
    setValue={(value) => setFormData((prevFormData) => ({ ...prevFormData, reporting_date: dayjs(value).format('YYYY-MM-DD') }))}
    value={reportingDate ? dayjs(reportingDate) : null} 
    date={reportingDate}
    setDate={(newDate) => setReportingDate(newDate)}
    placeholder="Reporting date"
    label="Reporting date"
    choices={['day', 'month', 'year']}
/>


                    <div className="popup-buttons">
                        <SecondaryButton onClick={handleCancel} type="button" buttonText="Cancel" />
                        <PrimaryButton isSubmitting={isSaving} buttonText="Save" type="submit" />
                    </div>
                </form>
            </div>
        </div>
    );
};

export default EditMeasureData;
