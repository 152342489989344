import React, { useEffect, useState } from 'react'
import api, { API_URL } from '../../api'
import { hospitalsCard } from '../../data/HospitalsList'
import HospitalCard from '../../components/hospitals/HospitalCard'
import HospitalFilters from '../../components/hospitals/HospitalFilters'
import BarGraph from '../../components/hospitals/HositalBarGraph'
import "../../assets/css/hospitals/hospitals.css"
import { DashboardTotalCardList } from "../../data/DashboardTotalCardList";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import DashboardTotalCard from "../../components/dashboard/DashboardTotalCard";
import DashboardContainer from '../../components/dashboard/DashboardContainer'
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import {Timer02Icon,BedIcon,SearchingIcon,WorkoutRunIcon,PlusSignIcon,} from "hugeicons-react";
import { PrimaryButton } from '../../components/forms/buttons'
import AddHospitalPopup from '../../components/hospitals Popup/AddHospitalPopup'
import { Link } from 'react-router-dom'
import Hospitals from './Hospitals'


const HospitalsPageContent = () => {
    const [hospitals, setHospitals] = useState([])
    const [isLoading, setIsLoading] = useState(true)
    const [totalData, setTotalData] = useState({});
    const [currentDateYear, setCurrentDateYear] = useState(
      new Date().getFullYear()
    );
    const [prevYear, setPrevYear] = useState(new Date().getFullYear() - 1);
  const [prevYearMinusTwo, setPrevYearMinusTwo] = useState(
    new Date().getFullYear() - 2
  );
  const [prevYearMinusThree, setPrevYearMinusThree] = useState(
    new Date().getFullYear() - 3
  );
  const [data, setData] = useState(null);
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [activeTab, setActiveTab] = useState('analytics'); 
  

  const fetchTotalsData = async (value) => {
      try {
        const response = await api.get(`${API_URL}/dashboard/dashboard_totals/${selectedYear}/${0}/`);
        if (response.status === 200) {
  
          setTotalData(response.data[Number(value)]);
        
        }
      } catch (error) {
        console.log(error);
      }
    };

  const handleAddHospital = async (value)=>{
    setIsPopupOpen(!isPopupOpen);
  }

    // Submit Add Popup
    const handlePopupSubmit = (e) => {
      e.preventDefault();
      handleClosePopup();
    };

    const handleClosePopup = () => {
      setIsPopupOpen(false);
      // setIsEditPopupOpen(false);
      // setIsDeletePopupOpen(false);
    };

    useEffect(() => {

        setTimeout(() => {
            setHospitals(hospitalsCard)
            setIsLoading(false)
        }, 2000);
    }, [])

    

    useEffect(() => {
      const fetchData = async () => {
        try {

          const response = await api.get(
            `${API_URL}/hospitals/data/`,

            {
              params:{"year":selectedYear}
            }
          );
          
          const result = response.data;
          
          setData(result);
        } catch (error) {
          console.error('Error fetching hospital data:', error);
        }
      };
  
      fetchData();
      fetchTotalsData(selectedYear);
    }, [selectedYear]);
  
    // const handleYearChange = (year) => {
    //   setSelectedYear(year);
    // };
    const handleDurationYearChange = (value) => {
      // handleFilter(value, filterDurationMonth, measureBy);
      // setFilterDurationYear(value);
      setSelectedYear(value)
    };
  
    if (!data) {
      return <p>Loading...</p>;
    }

    // const availableYears = Object.keys(data.totals);

    return isLoading ? 'Getting data..' : (
      <div className='hospitals-page'>
        <div className="header"></div>
        <div className="tabs">
          <div
            className={`tab ${activeTab === 'analytics' ? 'active' : ''}`}
            onClick={() => setActiveTab('analytics')}
          >
            Hospitals Analytics
          </div>
          <div
            className={`tab ${activeTab === 'allHospitals' ? 'active' : ''}`}
            onClick={() => setActiveTab('allHospitals')}
          >
          All Hospitals
{/* <Link to='/all_hospitals/' className='link'>All Hospitals</Link> */}
          </div>
        </div>
  
<div className='tab-contents'>
{activeTab === 'analytics' && (
          <div className='select-button'>
            <Select
              onChange={(e) => {
                handleDurationYearChange(e.target.value);
              }}
              name="duration"
              id="duration"
              value={selectedYear}
            >
              <MenuItem value={currentDateYear}>{currentDateYear}</MenuItem>
              <MenuItem value={prevYear}>{prevYear}</MenuItem>
              <MenuItem value={prevYearMinusTwo}>{prevYearMinusTwo}</MenuItem>
              <MenuItem value={prevYearMinusThree}>{prevYearMinusThree}</MenuItem>
            </Select>
        
            <PrimaryButton
              onClick={handleAddHospital}
              processingText={'Submitting'}
              iconClass={<PlusSignIcon />}
              buttonText={'Add Hospital'}
            />
            {isPopupOpen && (
              <AddHospitalPopup 
                onClose={handleClosePopup}
                onSubmit={handlePopupSubmit}
              />
            )}
          </div>
        )}
  
        {activeTab === 'analytics' && (
          <div className="total-hospital-cards">
            <DashboardTotalCard
              className="total-card"
              icon={
                <Timer02Icon size={24} color={"#07AEEF"} variant={"stroke"} />
              }
              value={
                totalData ? (
                  <h2>{totalData.total_acute}</h2>
                ) : (
                  <span>No Available Data</span>
                )
              }
              name={"Total Acute"}
            />
            <DashboardTotalCard
              className="total-card"
              icon={<BedIcon size={24} color={"#FFB60A"} variant={"stroke"} />}
              value={
                totalData ? (
                  <h2>{totalData.total_swing_bed}</h2>
                ) : (
                  <span>No Available Data</span>
                )
              }
              name={"Total Swingbed"}
            />
            <DashboardTotalCard
              className="total-card"
              icon={
                <SearchingIcon size={24} color={"#FC7D75"} variant={"stroke"} />
              }
              value={
                totalData ? (
                  <h2>{totalData.total_observation}</h2>
                ) : (
                  <span>No Available Data</span>
                )
              }
              name={"Total Observation"}
            />
            <DashboardTotalCard
              className="total-card"
              icon={
                <WorkoutRunIcon
                  size={24}
                  color={"#0E76BC"}
                  variant={"stroke"}
                />
              }
              value={
                totalData ? (
                  <h2>{totalData.total_emergency_room}</h2>
                ) : (
                  <span>No Available Data</span>
                )
              }
              name={"Total Emergency Room"}
            />
          </div>
        )}
  
        {activeTab === 'analytics' && <BarGraph data={data} year={selectedYear} />}
        {activeTab === 'allHospitals' && (
<div className='tab-contents-hospitals'>
      {/* Content from HospitalsContent */}
      <Hospitals />
    </div>
  )}
</div>
 
       
      </div>
    );
  
}
const HospitalsPage = () => {
    return (
        <DashboardContainer content={<HospitalsPageContent />} pageTitle={'All hospitals'} />
    )
}
export default HospitalsPage
